<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList" :showOutput="true"
			@clearSearch="clearSearch">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" style="width: 150px;" clearable placeholder="平台订单号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.group_name" size="small" style="width: 100px;" clearable placeholder="场站名称"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" style="width: 120px;" clearable placeholder="设备编号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.OperatorID" size="small" clearable style="width: 100px" placeholder="渠道">
					<el-option v-for="item in OperatorList" :label="item.operator_name" :value="item.OperatorID" :key="item.OperatorID"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.order_status" style="width: 120px;" placeholder="充电状态" clearable size="small">
					<el-option label="进行中" :value="10"></el-option>
					<el-option label="已取消" :value="20"></el-option>
					<el-option label="已完成" :value="30"></el-option>
				</el-select>
			</el-form-item>			
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.y_order_no" size="small" style="width: 150px;" clearable placeholder="业务订单号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.create_time" style="width: 240px;" clearable type="daterange" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" start-placeholder="结束时间" end-placeholder="结束时间" size="small">
				</el-date-picker>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="平台订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="y_order_no" title="业务订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="operator_name" title="渠道" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="city" title="城市" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="group_name" title="站点" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="device_id_text" title="设备编号" align="center" min-width="100px">
				<template v-slot="{ row }">
					<div v-if="!row.device_id">无</div>
					<div v-if="row.device_id">{{row.device_name}}</div>
					<div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}-{{row.plug_num}}</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="car_no" title="车牌" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="electric" title="订单电量" align="center" width="60px" />
			<vxe-table-column slot="table-item" field="order_price" title="订单金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="ele_price" title="订单电费" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="service_price" title="订单服务费" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="order_status_text" title="充电状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.order_status[row.order_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="duration_text" title="充电时长" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{$common.secondToTime(row.duration)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="create_time" title="充电开始时间" align="center" width="100px" />
			<vxe-table-column slot="table-item" field="end_time" title="充电结束时间" align="center" width="100px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="150">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)">详情</el-button>
					<el-button size="small" plain @click="endOne(row)" v-if="row.order_status == 10 && $hasAccess('hlht.manager/orderend')">结束</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 详情 -->
		<el-dialog title="订单详情" :visible.sync="detailDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="1200px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-row>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:0}">
						<!-- 订单信息 -->
						<div class="el-block">
							<div class="row-title">订单信息</div>
							<el-form-item label="平台订单号：">{{detailDialogFormData.order_no}}</el-form-item>
							<el-form-item label="业务订单号：">{{detailDialogFormData.y_order_no}}</el-form-item>
							<el-form-item label="渠道：">{{detailDialogFormData.operator_name}}</el-form-item>
							<el-form-item label="订单金额：">{{detailDialogFormData.order_price}}元</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:0}">
						<!-- 设备信息 -->
						<div class="el-block">
							<div class="row-title">电站信息</div>
							<el-form-item label="电站名称：">{{detailDialogFormData.group_name}}</el-form-item>
							<el-form-item label="城市：">{{detailDialogFormData.city}}</el-form-item>
							<el-form-item label="设备编号：">{{detailDialogFormData.device_id_text}}</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:0}">
						<div class="el-block">
							<div class="row-title">充电信息</div>
							<el-form-item label="充电状态：">{{types.order_status[detailDialogFormData.order_status]||'未知状态'}}</el-form-item>
							<el-form-item label="开始时间：">{{detailDialogFormData.create_time}}</el-form-item>
							<el-form-item label="结束时间：">{{detailDialogFormData.end_time}}</el-form-item>
							<el-form-item label="充电时长：">{{$common.secondToTime(detailDialogFormData.duration)}}</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:0}">
						<div class="el-block">
							<el-form-item label=""><br/></el-form-item>
							<el-form-item label="开始soc：">{{detailDialogFormData.bSoc}}%</el-form-item>
							<el-form-item label="结束soc：">{{detailDialogFormData.eSoc}}%</el-form-item>
							<el-form-item label="停止原因：">{{detailDialogFormData.FailReason}}</el-form-item>
							<el-form-item label="车牌：">{{detailDialogFormData.car_no}}</el-form-item>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :sm="{span:6,offset:0}">
						<div class="row-title">支付信息</div>
						<el-form-item label="订单电量：">{{detailDialogFormData.electric}}</el-form-item>
						<el-form-item label="订单金额：">{{detailDialogFormData.order_price}}</el-form-item>
						<el-form-item label="订单电费：">{{detailDialogFormData.ele_price}}</el-form-item>
						<el-form-item label="订单服务费：">{{detailDialogFormData.service_price}}</el-form-item>
					</el-col>
					<el-col :sm="{span:6,offset:0}">
						<el-form-item label=""><br/></el-form-item>
						<el-form-item label="支付状态："></el-form-item>
						<el-form-item label="支付时间："></el-form-item>
					</el-col>
					<el-col :sm="{span:6,offset:0}">
						<div class="row-title">分时计费</div>
						<el-form-item v-for="(cc,index) in detailDialogFormData.ChargeDetails" :key="index" :label="cc.DetailStartTime.substr(11) + ' ~ ' + cc.DetailEndTime.substr(11)">电量 {{ typeof(cc.DetailPower) == 'number'?cc.DetailPower.toFixed(4):cc.DetailPower }} 电费 {{cc.DetailElecMoney}} 服务费 {{ cc.DetailSeviceMoney }}</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'user-order-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '互联订单',
				isLoading: false,
				tableData: [],
				OperatorList: [], //组织列表
				totalPage: 0,
				searchFormData: {
					u_type: 10
				},
				types: {
					pay_status: {
						10: '未支付',
						20: '已支付',
						30: '退款',
						50: '无需支付'
					},
					order_status: {
						10: '进行中',
						20: '已取消',
						30: '已完成'
					},
					unusual_status: {
						10: '正常',
						20: '功率异常',
						30: '插座脱落',
						40: '信号异常'
					},
					is_auto: {
						10: '是',
						20: '否'
					},
					share_status: {
						10: '不结算',
						20: '未结算',
						30: '已结算'
					}
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: [],
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				detailDialogUserBalance: 0,
			}
		},
		mounted() {
			//渠道列表
			this.$api.Hlht.Manager.operatorlist({token: this.$store.state.user.token}).then((res)=>{
				this.OperatorList = res;
				for(let v of this.tableData){
					for(let op of this.OperatorList){
						if(op.OperatorID == v.OperatorID){
							v.operator_name = op.operator_name;
							break;
						}
					}
				}
			});

			this.$refs.xTable.exportTable = () => {
				let params = {
					token: this.$store.state.user.token,
					page: 1,
					size: this.totalPage + 1
				}
				params = Object.assign(params, this.searchFormData);
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!params.group_id && group_id) {
					params.group_id = group_id
				}
				this.$api.Hlht.Manager.order(params).then(res => {
					for(let v of res.data){
						if(!v.device_id){
							v.device_id_text = '无';
						}else if(v.device_id){
							v.device_id_text = v.device_name;
						}else{
							v.device_id_text = '编号:' + v.device_no + '-' + v.plug_num;
						}
						v.duration_text = this.$common.secondToTime(v.duration);
						if(this.OperatorList.length > 0){
							for(let op of this.OperatorList){
								if(op.OperatorID == v.OperatorID){
									v.operator_name = op.operator_name;
									break;
								}
							}
						}
					}
					this.$refs.xTable.exportTableAll(res.data)
				}).catch(err => {});
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = Object.assign({
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}, this.searchFormData)
				this.isLoading = true;
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!params.group_id && group_id) {
					params.group_id = group_id
				}
				const res = await this.$api.Hlht.Manager.order(params)
				this.tableData = res.data;
				for(let v of this.tableData){
					if(!v.device_id){
						v.device_id_text = '无';
					}else if(v.device_id){
						v.device_id_text = v.device_name;
					}else{
						v.device_id_text = '编号:' + v.device_no + '-' + v.plug_num;
					}
					v.duration_text = this.$common.secondToTime(v.duration);
					if(this.OperatorList.length > 0){
						for(let op of this.OperatorList){
							if(op.OperatorID == v.OperatorID){
								v.operator_name = op.operator_name;
								break;
							}
						}
					}
				}
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					u_type: 10
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
			},
			// 详情
			detailOne(row) {
				this.detailDialogFormData = row
				this.detailDialogShow = true
			},
			// 结束订单
			endOne(row) {
				this.$confirm('确定要结束订单吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					await this.$api.Hlht.Manager.orderEnd({
						token: this.$store.state.user.token,
						order_no: row.order_no,
						device_num: row.device_num,
						plug_num: row.plug_num,
					});
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					});
					this.$refs.xTable.refreshTable()
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
